import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    if (isMobileMenuOpen) {
      toggleMobileMenu();
    }
  };

  const menuLinks = [
    { name: 'Jobs', href: 'https://www.linkedin.com/company/rapsodo-studios/', external: true },
    { name: 'Games', to: '/games', external: false },
  ];

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="https://cdn.shopify.com/s/files/1/0597/7853/1477/files/header-rapsodo-logo.svg?v=1728046550" alt="Rapsodo Studios Logo" />
        </Link>
      </div>

      <nav className={`desktop-nav ${isMobileMenuOpen ? 'nav-hidden' : ''}`}>
        <ul>
          {menuLinks.map((link, index) => (
            <li key={index}>
              {link.external ? (
                <a
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                </a>
              ) : (
                <Link
                  to={link.to}
                  onClick={handleLinkClick}
                  className={location.pathname === link.to ? 'active' : ''}
                >
                  {link.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>

      <div className="hamburger" onClick={toggleMobileMenu} aria-label="Toggle mobile menu">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <div className={`mobile-menu-overlay ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="close-icon" onClick={toggleMobileMenu} aria-label="Close mobile menu">
          <span className="bar close-bar close-bar-1"></span>
          <span className="bar close-bar close-bar-2"></span>
        </div>

        <ul className="mobile-menu">
          {menuLinks.map((link, index) => (
            <li key={index}>
              {link.external ? (
                <a
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  {link.name}
                </a>
              ) : (
                <Link
                  to={link.to}
                  onClick={handleLinkClick}
                  className={location.pathname === link.to ? 'active' : ''}
                >
                  {link.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
      {isMobileMenuOpen && <div className="menu-overlay" onClick={toggleMobileMenu}></div>}
    </header>
  );
}

export default Header;

import React, { useEffect, useRef, useState, useCallback } from "react";
import '../Styles/Games.css';

function Games() {
  const videoEl = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [playError, setPlayError] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const attemptPlay = useCallback(() => {
    if (videoEl.current) {
      videoEl.current
        .play()
        .then(() => {
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error attempting to play", error);
          setPlayError(true);
        });
    }
  }, []);

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setVideoSrc("https://cdn.shopify.com/videos/c/o/v/018b24b94376493caa0a41275edd36d3.mp4");
      } else { // Masaüstü için
        setVideoSrc("https://cdn.shopify.com/videos/c/o/v/45d722854b2148248f383e2ff763fb3b.mp4");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    attemptPlay();
  }, [attemptPlay, videoSrc]);

  return (
    <div className="games">
      <div>
        {isLoading && <p>Video loading...</p>}
        {playError && <p>There was an error playing the video.</p>}

        <video
          style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
          playsInline
          loop
          muted
          title="Promotional video of all the devices"
          src={videoSrc}
          ref={videoEl}
        />
      </div>
    </div>
  );
}

export default Games;

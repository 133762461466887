import React from 'react';
import '../Styles/Privacy.css';
import privacyPolicyContent from '../Content/privacyPolicy';

function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p className="privacy-paragraph">{privacyPolicyContent.intro}</p>

            {privacyPolicyContent.sections.map((section, index) => (
                <div key={index} className="privacy-section">
                    <h2 className="privacy-subheading">{section.title}</h2>
                    {section.content && <p className="privacy-paragraph">{section.content}</p>}

                    {section.list && Array.isArray(section.list) && section.list.length > 0 && (
                        <ul className="privacy-list">
                            {section.list.map((item, idx) => (
                                <li key={idx} className="privacy-paragraph">
                                    {typeof item === 'object' ? (
                                        <>
                                            <strong>{item.bold}</strong> {item.text}
                                        </>
                                    ) : (
                                        item
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
}

export default PrivacyPolicy;

import React from 'react';
import '../Styles/Terms.css';
import termsContent from '../Content/termsContent';

function TermsOfUse() {
    return (
        <div className="terms-container">
            <h1>Terms of Use</h1>
            <p>{termsContent.intro}</p>

            {termsContent.sections.map((section, index) => (
                <div key={index} className="terms-section">
                    <h2>{section.title}</h2>
                    <p>{section.content}</p>
                </div>
            ))}
        </div>
    );
}

export default TermsOfUse;
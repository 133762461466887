import React, {useEffect, useState} from 'react';
import './Hero.css';

function Hero() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <section className="hero">
            <div className={`hero-content ${fadeIn ? 'fade-in' : ''}`}>
                <h1>WHO WE ARE?</h1>
                <p>
                    "At Rapsodo Studios, we’re passionate about crafting the future of entertainment. We specialize in
                    developing
                    immersive simulations and video games that captivate players and fans around the world. Our aim is
                    to elevate the
                    gaming experience through innovative, unforgettable content that leaves a lasting impression."
                </p>
            </div>
        </section>
    );
}

export default Hero;

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from "./components/Hero/Hero";
import Games from "./pages/Games";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
    return (
        <Router>
            <div className="App">
                <div className="container">
                    <Header />
                    <main>
                        <Routes>
                            <Route path="/" element={<Hero />} />
                            <Route path="/games" element={<Games />} />
                            <Route path="*" element={<NotFound />} />x
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/terms-of-use" element={<TermsOfUse />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
                <CookieConsent
                    debug={false}
                    location={"bottom"}
                    buttonText={"Got It"}
                    expires={7}
                    buttonStyle={{ background: "#fff", color: "black" }}
                >
                    This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.
                    <a href="/privacy-policy" style={{ color: 'white', textDecoration: 'underline' }}> Learn More</a>
                </CookieConsent>
            </div>
        </Router>
    );
}

export default App;

import React from 'react';
import './Footer.css';

function Footer() {
    const footerLinks = [
        {text: 'Privacy & Cookie Policy', href: '/privacy-policy'},
        {text: 'Terms of Use', href: '/terms-of-use'},
         {/*{text: 'FCC Statement', href: '#'},*/}
    ];

    const socialLinks = [
        {
            href: 'https://www.linkedin.com/company/rapsodo-studios/',
            src: 'https://cdn.shopify.com/s/files/1/0597/7853/1477/files/stuidos-linkedin-icon.svg?v=1728052307',
            alt: 'LinkedIn',
        },
        {
            href: 'https://www.instagram.com/rapsodostudios/',
            src: 'https://cdn.shopify.com/s/files/1/0597/7853/1477/files/studios-ig-icon.svg?v=1728052249',
            alt: 'Instagram',
        },
        {
            href: 'https://x.com/RapsodoStudios',
            src: 'https://cdn.shopify.com/s/files/1/0597/7853/1477/files/studios-x-icon.svg?v=1728052377',
            alt: 'Twitter',
        },
        {
            href: 'https://www.youtube.com/@RapsodoStudios',
            src: 'https://cdn.shopify.com/s/files/1/0597/7853/1477/files/Company_YouTube_Style_Gray_Background_Circular.svg?v=1729168054',
            alt: 'Youtube',
        },
    ];

    return (
        <footer className="footer">
             {/*<div className="footer-logo">

                <img src="https://cdn.shopify.com/s/files/1/0597/7853/1477/files/header-rapsodo-logo.svg?v=1728046550"
                     alt="Rapsodo Studios Logo"/>

            </div>*/}
            <div className="footer-middle">
                <div className="footer-links">
                    <ul>
                        <img
                            src="https://cdn.shopify.com/s/files/1/0597/7853/1477/files/header-rapsodo-logo.svg?v=1728046550"
                            alt="Rapsodo Studios Logo"/>
                    </ul>
                </div>
                <div className="footer-social">
                    {socialLinks.map((link, index) => (
                        <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                            <img src={link.src} alt={link.alt}/>
                        </a>
                    ))}
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2024 Rapsodo Studios. All rights reserved</p>
                <div className="footer-legal-links">
                    {footerLinks.map((link, index) => (
                        <a key={index} href={link.href}>{link.text}</a>
                    ))}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
